import { $$t } from "~~/utils/i19m"
import Controls from "./Controls"

class Player {
  constructor() {
    this.data = {}
  }

  fromData(videoUrl, data, externalVideoId) {
    if (data) {
      this.data = data
    } else {
      // default data
      this.data = {
        autoplay: true,
        autostartOnLoad: {
          state: true,
          onMobile: false,
          onData: false,
        },
        video: {
          file: null,
        },
        pauseOtherVideos: false,
        volume: 0.5,
        muted: true,
        debug: false,
        float: {
          state: false,
          position: "BOTTOM_RIGHT",
          dismissible: true,
        },
        controls: new Controls(),
        lang: {
          controls: {
            play: "Play",
            pause: "Pause",
            next: "Next",
            replay: "Replay",
            volume: "Volume",
            mute: "Mute",
            settings: "Settings",
            theater: "Theater",
            fullscreen: "Full Screen",
            chromecast: "Chromecast",
            airplay: "Airplay",
          },
          settings: {
            quality: "Quality",
            subtitles: "Subtitles",
          },
          ads: {
            skip: "Skip",
            skipIn: "Skip in: ",
            visit: "Visit US",
            info: "Read more",
          },
        },
        skin: {
          controlBar: {
            background: null,
            text: "#FFFFFF",
            icons: {
              default: "#FFFFFF",
              hover: "#FFFFFF",
            },
          },
        },
      }
    }

    this.setVideoFile(videoUrl)

    if (externalVideoId) this.setVideoId(externalVideoId)
  }

  setTagsOnPlayerConfig() {
    if (typeof __VPAPPS__ === "undefined") {
      return
    }

    const tags = __VPAPPS__?.media?.tags
    if (!tags) return

    // const tags = data.filter(t => t.includes('rt_'))
    this.data.video.tags = tags
  }

  setVideoFile(videoUrl) {
    this.data.video.file = videoUrl
  }

  setVideoId(id) {
    this.data.video.videoId = id
  }

  setVideoLockMessage(data) {
    if (!data) return
    this.data.config.lang.messages.videoLockedTitle = data
  }
  setVideoLockConfig(data, user) {
    if (!data) return
    this.data.video.videoLocking = {
      isEnabled: false,
      type: "seconds",
      value: data,
      customView: {
        state: true,
        backgroundColor: "transparent",
        button: {
          text: user ? "" : $$t("component_player_login_redirect"),
          color: "#ffffff",
        },
      },
    }
  }
  enableVideoLock() {
    this.data.video.videoLocking.isEnabled = true
  }

  setMediaTracks(data) {
    if (!data || data.length === 0) return

    const tracksFitler = data.map((t) => {
      return {
        file: t.url,
        label: t.language,
        lang: t.abbreviation,
        checked: t.checked,
      }
    })
    this.data.video.tracks = tracksFitler
  }
  // customOverlays, productPlacements
  setProductPlacements(productPlacements) {
    if (!productPlacements?.length) return

    const overlays = productPlacements.map((e) => ({
      position: 8,
      content: $$t("component_player_product_placement"),
      from: e.fromSecond,
      to: e.toSecond,
    }))

    this.data.video.customOverlays = {
      state: true,
      overlays,
    }
  }

  setConfigByKey(key, value) {
    if (!key) return
    this.data.config[key] = value
  }
}

export default Player
